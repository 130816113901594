<template>
    <v-container fluid>
        <section class="section-panel bg-secondary">
            <div class="constraint">
                <div>
                    <h1>
                        Custom Experiences
                    </h1>
                    <p class="font-xl mb-5">
                        Reduce Anxiety in Everyday Life.<br /><br />
                        Let us work with you to create an experience to meet your specific needs.
                        <br /><br />
                        We have worked alongside schools and businesses to create Custom Experiences designed with their students and
                        clients’ needs in mind.<br /><br />
                        Your Interactive Custom Experience can be accessed via your website or a dedicated email invitation and can be
                        viewed on various devices such as computers, tablets, and phones..<br /><br />
                        By interacting with these experiences repeatedly, individuals can become more comfortable with your
                        environment or procedure which helps to alleviate anxiety.<br /><br />
                        No special software, app or coding required.
                    </p>
                    <router-link class="ux-button" :to="{ name: 'contact-us' }">
                        Get in touch
                    </router-link>
                </div>
            </div>
        </section>
        <section class="section-panel">
            <div class="constraint">
                <div class="text">
                    <h2>
                        360˚ Interactive Explore
                    </h2>
                    <p class="font-xl mb-5">
                        Our 360˚ Interactive Explore, helps students and children easily navigate through a new environment.
                        Each page is a professional 360 image of a space you would like to introduce. Students can explore
                        each space with confidence and interact with additional information and voice over as they
                        familiarize themselves with the process of transition. This content works well for visual learners
                        and especially for introducing a change in environment. These experiences can easily be upgraded and
                        new content added as your transition needs change or your space grows.
                    </p>
                    <a class="ux-button" href="https://bit.ly/th-ps-360-walkthrough-2021" target="_blank">
                        View an example
                    </a>
                    <router-link class="ux-button" :to="{ name: 'contact-us' }">
                        Find out more...
                    </router-link>
                </div>
                <div class="image">
                    <v-img :src="assets.bespoke.virtualTourIcon" cover />
                </div>
            </div>
        </section>

        <section class="section-panel reverse bg-secondary">
            <div class="constraint">
                <div class="text">
                    <h2>
                         Interactive Transition Experience
                    </h2>
                    <p class="font-xl mb-5">
                        Professionally written and photographed with voice over, we create an Interactive Experience specific to
                                            your transition needs. We work alongside you to produce an Interactive Experience that will help your
                                            child or student navigate a new situation, giving guidance and directions for how to respond and behave
                                            in a specific social situation. Fully interactive with 360 image content included, students will find
                                            this new experience engaging and fun while learning key information. This content works well for
                                            learning information and process.
                    </p>
                   
                </div>
                <div class="image">
                    <v-row v-if="!isSmallScreen" class="d-flex  gap-3">
                                    <!-- <div class="mx-2 clicker">
                                        <v-img class="my-6" style="width: 12vw; max-width: 52em" :src="assets.bespoke.bookCoverPolice" @click="goTo('The Police Are Here to Help')"></v-img>
                                        Click to view
                                    </div> -->


                                    

                                    <a class="mx-2 clicker" href="https://bit.ly/eastern-ranges-ps-transition-2021" target="_blank">
                                        <v-img v-if="!isSmallScreen" class="my-6" style="width: 12vw; max-width: 52em" :src="assets.bespoke.bookEasternRanges"></v-img>
                                        Click to view
                                    </a>



                                    <div class="mx-2 clicker">
                                        <v-img v-if="!isSmallScreen" class="my-6" style="width: 12vw; max-width: 52em" :src="assets.bespoke.bookNippers" @click="goTo('Welcome to Nippers')"></v-img>
                                        Click to view
                                    </div>
                                </v-row>
                </div>
            </div>
        </section>
       
    </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
    mixins: [],
    meta: {},
    data() {
        return {
            // birdsSVG: require('@/assets/icons/bespoke/birdsTransitions.svg'),
            // mailSVG: require('@/assets/icons/bespoke/email.svg'),
            // threeIconsSVG: require('@/assets/icons/bespoke/threeIcons.svg'),
            // bookHairdresser: require('@/assets/book-covers/RedSparrowBooks_BookCovers_Icons_FINAL_Artboard 4_Hairdresser.jpg'),
            dialog: false,
            isSmallScreen: false,
        };
    },

    computed: {
        ...mapState(["assets"]),

        icons() {
            return [{
                    title: "Nippers Icon",
                    src: this.assets.bespoke.nippersIcon,
                    link: "",
                },
                {
                    title: "Police Icon",
                    src: this.assets.bespoke.policeIcon,
                    link: "",
                },
                {
                    title: "Virtual Tour Icon",
                    src: this.assets.bespoke.virtualTourIcon,
                    link: "",
                },
            ];
        },
    },
    mounted() {
        window.addEventListener("resize", this.checkScreenSize);
        this.checkScreenSize();
    },
    methods: {
        goTo(libraryBook) {
            console.log("Opening Link", libraryBook);
            this.$router.push({
                name: "library",
                params: {
                    book: libraryBook,
                },
            });
        },
        checkScreenSize() {
            this.isSmallScreen = window.innerWidth < 600; // Change this value to adjust the screen size threshold
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.checkScreenSize);
    },
};
</script>
<style lang="scss" scoped>
.icon-size {
    width: 6em;
}

.screen-width {
    width: 90%;
}

.clicker {
    text-align: center;
    color: inherit;
}

@media (min-width: 600px) {
    .icon-size {
        width: 10em;
    }

    .screen-width {
        width: 70%;
    }
}

.bespoke-icons {
    max-width: 100%;

    @media (min-width: 200px) {
        width: 200px;
    }
}

.experience-icon {
    cursor: pointer;
}
</style>